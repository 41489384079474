<template>
	<WDialog
		v-model="dialog"
		max-width="400px"
		:title="$t('user.titles.delete', { firstname: currentAccountant.firstname, lastname: currentAccountant.lastname })"
	>
		<v-layout>{{ $t('user.titles.deletedialog') }}</v-layout>
		<template v-slot:actions>
			<v-layout align-center justify-end row>
				<WButton flat @click="$emit('close')">{{ $t('actions.cancel') }}</WButton>
				<WButton flat @click="deleteAccountant">{{ $t('actions.delete') }}</WButton>
			</v-layout>
		</template>
	</WDialog>
</template>

<script>
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManagerDialogDelete',
	mixins: [TeamManagerModuleGuard],
	props: {
		accountant: {
			required: true,
			type: Object
		},
		accountingFirm: {
			required: true,
			type: Object
		},
		dialogDelete: {
			required: true,
			type: Boolean
		},
		isAccountantAdmin: {
			required: true,
			type: Boolean
		},
		user: {
			required: true,
			type: Object
		}
	},
	data () {
		return {
			currentAccountant: {}
		}
	},
	computed: {
		dialog: {
			get: function () {
				return this.dialogDelete
			},
			set: function (value) {
				if (!value) {
					this.$emit('close')
				}
			}
		}
	},
	watch: {
		panel: function (val) {
			if (this.hasAccess[val]) {
				this.themes = []
				this.show = true
				const clients = this.clients
				const vendor = this.currentAccountant.vendors.find(v => clients[val].id === v.id)
				this.themes = vendor.themes.map(t => t.theme_id || t)
			} else {
				this.show = false
			}
		},
		dialogDelete: function (val) {
			if (val) {
				this.init(this.accountant)
			}
		}
	},
	methods: {
		init: function (accountant) {
			if (this.user.id === accountant.id) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('user.titles.error.autodelete'))
			} else if (!this.isAccountantAdmin) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('user.titles.error.admin'))
			} else {
				this.currentAccountant = accountant
			}
		},
		deleteAccountant: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.service
				.deleteAccountant(this.accountingFirm.id, this.currentAccountant.id)
				.then(() => {
					this.$emit('delete', this.currentAccountant)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('accounting_firms.messages.teammate_deleted'))
					this.currentAccountant = {}
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		}
	}
}
</script>
